<!-- 新闻列表 -->
<template>
  <div>
    <div class="header">
      <div>
        <h3>{{ text1 }}</h3>
      </div>
    </div>
    <div class="content">
      <div class="content_center">
        <h3>{{ article.title }}</h3>
        <h4>—— {{ article.date }} ——</h4>
        <article v-html="article.content"></article>
      </div>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
const dataUrl = "https://www.futuregenbiopharm.com/manage/public/index.php/api/";
import { mapState } from "vuex";
const axios = require("axios");

// 获取新闻详情
function getArticleDetails() {
  axios({
    url: `${dataUrl}Index/articleInfo`,
    method: "post",
    data: "new_id=" + this.$route.query.id,
  })
    .then(res => {
      this.article = res.data.data;
    })
    .catch(err => {
      console.log(err);
    });
}
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "新闻中心",
          text2: "媒体报道",
          article: {},
          getArticleDetails,
        };
      case "en":
        return {
          text1: "Company news",
          text2: "media coverage",
          article: {},
          getArticleDetails,
        };
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
  mounted() {
    this.$nextTick(function () {
      this.getArticleDetails();
    });
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 400px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/xinwen/header.png") no-repeat;
  background-size: 100% 100%;
  div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 50px;
      color: #fff;
      padding-top: 20%;
    }
    h3::after {
      position: absolute;
      top: 110%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #fff;
    }
  }
}
.content {
  width: 100%;
  .content_nav {
    height: 130px;
    text-align: center;
    line-height: 130px;
    background-color: rgba(204, 204, 204, 0.2);
    a {
      position: relative;
      height: 130px;
      color: #333;
      font-size: 22px;
      margin-right: 80px;
      font-weight: 600;
      opacity: 0.7;
      -webkit-tap-highlight-color: transparent;
    }
    :nth-child(1) {
      color: #c00000;
      padding-bottom: 50px;
    }
    .line_red::after {
      position: absolute;
      top: 100%;
      left: 0%;
      content: "";
      width: 79px;
      height: 2px;
      opacity: 0.6;
      background-color: #c00000;
    }
  }
  .content_center {
    width: 100%;
    max-width: 1200px;
    margin: 50px auto 150px auto;
    color: #203864;
    h3 {
      text-align: center;
      margin: 0 auto;
      font-size: 40px;
      font-weight: normal;
    }
    h4 {
      text-align: center;
      margin: 30px;
      opacity: 0.7;
      font-size: 16px;
    }
    p {
      font-size: 16px;
      color: #4a4a4a;
      line-height: 32px;
      margin-top: 8px;
      span {
        color: #203864;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 150px;
    div {
      h3 {
        font-size: 24px;
        margin-left: 10%;
        font-weight: normal;
        padding-top: 20%;
      }
      h3::after {
        width: 30px;
      }
    }
  }
  .content {
    .content_nav {
      height: 70px;
      line-height: 70px;
      display: flex;
      justify-content: space-between;
      a {
        width: 50%;
        height: 70px;
        font-size: 22px;
        margin-right: 0px;
        font-weight: 600;
        opacity: 0.7;
      }
      :nth-child(1) {
        padding-bottom: 0px;
      }
      .line_red::after {
        left: 30%;
      }
    }
    .content_center {
      width: 100%;
      max-width: 1200px;
      margin: 0px auto;
      padding: 20px;
      box-sizing: border-box;
      h3 {
        width: 85vw;
        font-size: 24px;
      }
      h4 {
        margin: 10px;
        opacity: 0.7;
        font-size: 14px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
        margin-top: 8px;
        span {
          color: #203864;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
<style lang="less">
@media screen and (max-width: 750px) {
  .content {
    .content_center {
      article {
        img {
          width: 90% !important;
          height: auto !important;
        }
      }
    }
  }
}
</style>
